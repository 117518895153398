/* eslint-disable vue/valid-v-model */
/* eslint-disable vue/no-parsing-error */
/* eslint-disable vue/no-use-v-if-with-v-for */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable vue/no-use-v-if-with-v-for */
/* eslint-disable vue/valid-v-for */
<template>
  <v-container class="center">
    <v-layout text-center wrap>
      <v-container v-if="!displayBusquedaSeguimiento">
        <h2>Bienvenido al portal de Seguimiento</h2>
        <p>Aquí podrás hacer seguimiento a las órdenes generadas en Thor</p>
      </v-container>

      <!-- Main Inputs Container -->
      <v-container mt-4 v-if="displayBusquedaSeguimiento">
        <v-img
          class="ma-2"
          src='https://upload.wikimedia.org/wikipedia/commons/5/54/Entel_logo_pe.png'
          contain
          height="50"
        ></v-img>
        <!-- <h4 class="  mt-4 mb-8">Ingresa un número identificador de pedido</h4> -->

        <v-row class="center pa-2">
          <!-- <v-col xl="6" lg="8" md="12"> -->
          <v-col xl="9">
            <v-row class="justify-center pa-2 ma-3">
              <p class="justify-center">
                Bienvenid@ a nuestro portal de agendamiento.
              </p>
              <p>
                A continuación podrás realizar el re agendamiento de la visita y
                además seleccionar el día y ventana horaria en que deseas recibir tu producto
              </p>
            </v-row>
            <v-row style="display: none" class="center">
              <v-col md="6" id="inputContainer" class="center">
                <v-text-field
                  class="centered-input"
                  v-on:keyup.enter="getOrderDetails"
                  v-model="orderID"
                  color="#311def"
                  @click:clear="clearOrder"
                  placeholder="Ingresa el identificador de la entrega"
                  :maxlength="maxInputLength"
                  :autofocus="true"
                  :shaped="false"
                  :outlined="true"
                  :rounded="false"
                  :single-line="true"
                  :clearable="true"
                  :persistent-hint="false"
                  :dense="false"
                  :fullWidth="false"
                  :loading="showLoadingContainer"
                ></v-text-field>
                <span class="pa-0 ma-0" id="submitSection">
                  <transition name="fade">
                    <div v-if="!disabledButton">
                      <v-btn
                        @click="getOrderDetails"
                        v-if="showSearchButton"
                        color="rayo"
                        dark
                        large
                        >Buscar</v-btn
                      >
                    </div>
                    <div v-else>
                      <v-btn
                        v-if="showSearchButton"
                        color="secondary"
                        dark
                        large
                        >Buscar</v-btn
                      >
                    </div>
                  </transition>
                </span>
                <!-- <v-divider></v-divider> -->
              </v-col>
            </v-row>

            <v-row class="center pa-2">
              <v-col md="9">
                <form ref="form">
                  <vuetify-google-autocomplete
                    required
                    ref="address"
                    color="red"
                    id="map"
                    classname="form-control"
                    placeholder="Ingresa la dirección de entrega"
                    v-on:placechanged="getAddressData"
                    v-on:no-results-found="noResults"
                    country="cl"
                    v-model="addressOkObject.name"
                  >
                    <template #append>
                      <v-icon>mdi-map-search </v-icon>
                    </template>
                  </vuetify-google-autocomplete>

                  <v-text-field
                    color="red"
                    append-icon="mdi-information"
                    v-model="addressDetails"
                    label="Detalles de la dirección (departamento, casa, información adicional)"
                  ></v-text-field>

                   <v-text-field
                    color="red"
                    append-icon="mdi-information"
                    v-model="name"
                    label="Nombre de receptor(a)"
                  ></v-text-field>

                  <v-text-field
                    color="red"
                    append-icon="mdi-phone"
                    v-model="phone"
                    label="Teléfono actualizado"
                    required
                    prefix="+569 - "
                    :rules="rules.phoneRules"
                  >
                    <template>
                      <v-icon slot="prepend" color="red"> +569- </v-icon>
                    </template>
                  </v-text-field>
                  <h4>Selecciona la fecha de entrega</h4>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
              
                    <v-radio-group row v-model="selectedDate">
                      <v-radio
                        color="black"
                        v-for="r in availableDates"
                        v-bind:key="r.date"
                        :label="r.date"
                        :value="r.date"
                      >
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <v-btn @click="reset"> Limpiar datos </v-btn>
                  <v-btn
                    color="#311eee"
                    class="white--text ml-4"
                    @click="validate"
                  >
                    CONFIRMAR
                  </v-btn>
                </form>

                <!-- /// fin form -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Error Message Container -->
        <!-- <v-container>
					<v-row no-gutters class="mt-2" align="center" justify="center">
						<v-col cols="9" sm="8" md="6" lg="5" xl="5">
							<transition name="fade">
								<div id="errorMessageSection" class="mb-12">
									<transition name="fade">
										<v-alert dense :class="classAlert" :type="typeAlert" elevation="3">
											{{ errorMessage }}
										</v-alert>
									</transition>
								</div>
							</transition>
						</v-col>
					</v-row>
				</v-container> -->
        <!-- End Error Container Message -->
      </v-container>
      <!-- End Main Input Container -->

      <v-container text-left v-if="showTimeLineContainer" fluid>
        <!-- <v-row no-gutters align="center">
					<v-col xl="6" md="12" sm="12" xs="12">
						<span class="headline ml-11 ">Estado de la entrega: </span>
						<v-chip class="ma-2 br-4 mb-6" color="rayo" text-color="white" large>{{
							this.tipoOrden
						}}</v-chip>
						<span><v-chip class="ma-2 br-4 mb-2" color="rayo" text-color="white" large>PickUp y Delivery</v-chip></span>
					</v-col>

					<v-col xl="6" md="6">
						<h4 class="headline font-weight-bold mb-2">Estado del pedido:</h4>
						<v-chip class="ma-2 br-4" color="rayo" text-color="white" large>{{
							this.estadoOrden
						}}</v-chip>
					</v-col>
				</v-row> -->

        <v-row no-gutters align="center" justify="center">
          <!-- <v-col xl="6" lg="8" md="12"> -->

          <!-- <v-chip class="ma-2 br-4 mb-6" color="rayo" text-color="white" large>{{
							this.tipoOrden
						}}</v-chip> -->
          <!-- <span><v-chip class="ma-2 br-4 mb-2" color="rayo" text-color="white" large>PickUp y Delivery</v-chip></span> -->
          <!-- </v-col> -->
          <v-col style="display:none" xl="6" lg="8" md="12">
            <span class="overline ml-12">Historial de la entrega: </span>
            <!-- <v-divider class="ml-12"></v-divider> -->
            <transition name="fade">
              <div>
                <!-- Loading Progress -->
                <v-progress-circular
                  class="mb-12"
                  v-if="showLoadingContainer"
                  :size="50"
                  :width="3.5"
                  color="rayo"
                  indeterminate
                ></v-progress-circular>
                <!-- End Loading Progress -->

                <!-- Timeline Section -->
                <!-- :dense="$vuetify.breakpoint.smAndDown" -->
                <!-- :reverse="true" -->
                <div v-else>
                  <v-timeline
                    v-if="showTimeLineContainer"
                    :align-top="true"
                    small
                    dense
                  >
                    <v-timeline-item
                      v-for="(item, i) in dataOrderItems"
                      :key="i"
                      :fill-dot="fillDot"
                      class="ma-1"
                      small
                      color="#311eee"
                    >
                      <!-- <template v-slot:opposite>
												<span>
													<div v-html="item.dateFormat"></div>
												</span>
											</template> -->
                      <!-- <v-card dark class="pa-1"  > -->
                      <v-card class="grey lighten-3">
                        <!-- <span class="text-left">{{item.dateFormat}}</span> -->
                        <v-card-title class="pa-2">
                          <!-- <div v-html="item.label" /> -->

                          <v-chip
                            class="radiusChip mr-1 pa-2"
                            :color="item.label.color"
                            label
                            pill
                            x-small
                            >{{ fixNamesJuan(item.label.status) }}</v-chip
                          >

                          <!-- <span class="secondary-title mx-4 ml-4"> -->
                          <span class="text-caption text-md-body-2 mx-2 ml-3">
                            <v-row>
                              <!-- {{  iconType(item) }} {{ item.code_task }} -->

                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon :color="item.taskTypeInfo.color">
                                      {{ item.taskTypeInfo.icon }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>
                                  {{ item.taskTypeInfo.label }} id:{{
                                    item.code_task
                                  }}
                                </span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon :color="item.DC.color">
                                      {{ item.DC.icon }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.DC.label }} </span>
                              </v-tooltip>
                            </v-row>
                            <!-- <v-row class="mt-0">
															{{ item.code_task }}
														</v-row> -->
                          </span>
                          <!-- <span :class="item.label.class"> </span> -->
                          <!-- <p v-if="!isMobile" class="fs-2" v-html="item.date"></p> -->
                        </v-card-title>

                        <!-- <div class="pl-4 pb-2 text-left text-body-2">{{item.dateFormat}}</div> -->

                        <div
                          class="
                            white
                            text--primary text-caption text-md-body-2
                          "
                        >
                          <!-- <v-card-title  class="title "> -->
                          <div class="pl-4 py-2 text-left">
                            <!-- <div v-if="item.typeEvent != 'nota'"> -->
                            <span class=""> {{ item.dateFormat }} :</span>
                            {{ item.description }}
                            <!-- </div> -->

                            <!-- // getHistoricItemLabel -->

                            <!-- 
<v-row>
    <v-col
      v-for="n in 9"
      :key="n"
      class="d-flex child-flex"
      cols="4"
    >
      <v-img
        :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
        :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row> -->

                            <!-- // grilla -->

                            <div
                              v-if="
                                item.typeEvent === 'adjunto' &&
                                item.attachment !== null
                              "
                            >
                              <div>
                                <v-row class="my-3" cols="12">
                                  <v-col
                                    v-for="(p, x) in item.attachment"
                                    v-bind:key="x"
                                    class="d-flex child-flex"
                                    cols="4"
                                  >
                                    <!-- <v-img
        :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
        :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
        aspect-ratio="1"
        class="grey lighten-2"
     /> -->
                                    <expandable-image
                                      :close-on-background-click="true"
                                      aspect-ratio="1"
                                      :src="item.attachment[x]"
                                      class="grey lighten-3"
                                    >
                                      <template
                                        v-if="item.attachment[x] !== null"
                                        v-slot:placeholder
                                      >
                                        <v-row
                                          class="fill-height ma-0"
                                          align="center"
                                          justify="center"
                                        >
                                          <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                          ></v-progress-circular>
                                        </v-row>
                                      </template>
                                    </expandable-image>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                            <div v-if="item.typeEvent === 'nota'">
                              <v-row v-if="item.nota !== null">
                                <v-col class="d-flex text-left">
                                  <span class=""
                                    >{{ item.dateFormat }}, Se añadidó una nota:
                                  </span>
                                  <span>{{ item.nota }}</span>
                                </v-col>
                              </v-row>
                              <!-- <v-row v-if="item.nota !== null">
																	<v-col class="d-flex justify-center">
																			<v-hover v-slot:default="{ hover }">
																				<v-card
																					class="noteComment"
																					:elevation="hover ? 14 : 6"
																					:class="{ 'on-hover': hover }"
																					>
																					{{item.nota}}
																				</v-card>
																			</v-hover>
																	</v-col>
															</v-row> -->
                            </div>
                            <div v-if="item.typeEvent === 'dropdown'">
                              <v-row v-if="item.dropdown !== null">
                                <v-col class="d-flex justify-center">
                                  Rayo ha seleccionado una opción desde el menú
                                  deplegable:
                                </v-col>
                              </v-row>
                              <v-row v-if="item.dropdown !== null">
                                <v-col class="d-flex justify-center">
                                  <v-hover v-slot:default="{ hover }">
                                    <v-card
                                      style="padding: 5px"
                                      :elevation="hover ? 14 : 6"
                                      :class="{ 'on-hover': hover }"
                                    >
                                      {{ item.dropdown }}
                                    </v-card>
                                  </v-hover>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                          <!-- </v-card-title> -->
                        </div>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                  <!-- End Timeline Section -->
                </div>
              </div>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <div id="containerTopScroll">
      <GoTop
        :size="70"
        bg-color="#311def"
        :right="20"
        :bottom="75"
        :bottom-gap="10"
        :z-index="100"
        fg-color="#fff"
        :radius="5"
        weight="bold"
        ripple-bg="rgba(0, 0, 0, .5)"
        :boundary="500"
        :max-width="0"
        :has-outline="false"
        box-shadow="3px 3px 3px rgba(38, 9, 11, 0.29)"
        :tabindex="-1"
      />
    </div>

    <v-snackbar
      class="pa-2"
      color="#311def"
      :timeout="5000"
      :centered="true"
      v-model="snackAlerta"
    >
      <p class="subtitle-1 mb-n1">{{ snackText }}</p>
    </v-snackbar>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="black" dark>
        <v-card-text class="pa-3">
          Estamos procesando su pedido...
          <v-progress-linear
            indeterminate
            color="blue"
            class="mb-0 my-4 py-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFalse" hide-overlay persistent width="350">
      <v-card color="black" dark>
        <v-card-text class="pa-3">
          Debes seleccionar una dirección desde la sugerencia de direcciones.
          <v-progress-linear
            indeterminate
            color="blue"
            class="mb-0 my-4 py-3"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue" class="white--text" @click="dialogFalse = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
/* These styles need to be declared here */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-progress-circular {
  margin: 1rem;
}

.radiusChip {
  border-radius: 4px !important;
  height: 30px !important ;
}
</style>


<script>
import axios from "axios";
import GoTop from "@inotom/vue-go-top";
import moment from "moment";
import { isMobile } from "mobile-device-detect";

export default {
  name: "BodyPage",
  components: {
    GoTop,
  },
  data: function () {
    return {
       name:"",
       
       dialog: false,
       dialogFalse: false,
      valid: false,
      rules: {
        phoneRules: [
          (v) =>
            !!v || "El teléfono es necesario para dar seguimiento a tu pedido",
          (v) =>
            (v != null && v.length == 8) ||
            "El teléfono debe tener 8 números después de +569.",

          // v.match(
          //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          //     ) || "El formato del email debe ser valido (xyz@abc.com)"
        ],
      },

      phone: "",    
      addressOkObject:{valid: false, name:null, coords:{latitude:null, longitude:null}},
      // addressOk: null,
      addressDetails: "",
      availableDates: [
        { date: moment(this.failedDate).add(0, "day").format("YYYY-MM-DD") + ' - AM' },
        { date: moment(this.failedDate).add(0, "day").format("YYYY-MM-DD") + ' - PM' },
        { date: moment(this.failedDate).add(1, "day").format("YYYY-MM-DD")+ ' - AM' } ,
        { date: moment(this.failedDate).add(1, "day").format("YYYY-MM-DD")+ ' - PM' } ,
        { date: moment(this.failedDate).add(2, "day").format("YYYY-MM-DD")+ ' - AM' } ,
        { date: moment(this.failedDate).add(2, "day").format("YYYY-MM-DD")+ ' - PM' } ,
      ],
      selectedDate: null,
      fillDot: false,
      orderID: this.$route.query.id,
      failedDate: this.$route.query.failedDate,
      maxInputLength: 80,
      showSearchButton: true,
      showTimeLineContainer: false,
      showLoadingContainer: false,
      dataOrderItems: [],
      disabledButton: false,
      classAlert: "fade-enter",
      typeAlert: "warning",
      errorOnCall: false,
      errorMessage: "",
      topButtonSize: 0,
      invalidCallCounter: 0,
      notFoundOrderCounter: 0,
      snackAlerta: false,
      snackText: "",

      tipoOrden: "",

      //System Status
      statusArray: [],
    };
  },
  //Function executed before mounting the current component
  beforeMount() {
    if (isMobile) {
      this.topButtonSize = 45;
    } else {
      this.topButtonSize = 65;
    }
  },
  //Function executed when component successfully created
  // created() {
	// let doc = document.createElement('script');  
	// doc.setAttribute('src',"https://static.zdassets.com/ekr/snippet.js?key=64a0ac7d-a8e2-497f-bdcb-79ca1290f35e");
	// document.head.appendChild(doc);

  // },
  mounted() {
    this.evalOrderInURL();
    this.setStatusArray();

  },
  computed: {
    hoy() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    customerName() {
      return this.$route.name;
    },
    displayBusquedaSeguimiento() {
      let customerName = this.customerName;
      return customerName !== null && customerName !== "" ? true : false;
    },
  },
  // watch: {
  //     dialog (val) {
  //       if (!val) return

  //       setTimeout(() => (this.dialog = false), 2000)
  //     },
  //   },
  methods: {
      // envio    
      envio(postIn){     
        axios
        .post(
            "https://www",
           postIn
          )
          .then((response) => {
            let arrayData = response.data.data[0];
            // eslint-disable-next-line no-console
            console.log(arrayData);
            if (arrayData !== undefined) {
                // eslint-disable-next-line no-console
            console.log(33);
            } else {
              this.snackText = "No se ha encontrado la orden en el sistema.";
              this.snackAlerta = true;
              this.displayError({
                error: "xxxxxxx",
                typeAlert: "error",
                displayTime: 3333,
              });
              this.dialog = false
            
            }
          })
          .catch((error) => {
           
           // eslint-disable-next-line no-console
           console.log(error)
           
            this.snackText = "No se ha encontrado la orden en el sistema.";
              this.snackAlerta = true;
                this.displayError({
              error:
                "Ha ocurrido un problema al obtener la información de seguimiento.",
              typeAlert: "error",
              displayTime: 3333,
            });      
          })
      },

        // envio
      validate () {
        if(this.selectedDate ===null || !this.addressOkObject.valid  || this.addressOkObject.name ===null || this.addressOkObject.name === '' || this.phone.length !== 8) {
          this.addressOkObject.name = null
          this.validForm=false

          this.dialogFalse =true
        } else {
          this.validForm=true
        }

        if(this.validForm) {
          this.dialog =true
          let bloque = this.selectedDate.slice(-2)
          
          let envio = {
            address: this.addressOkObject.name,
            addressDetails: this.addressDetails,
            phone:'+569'+this.phone.toString(),
            date:this.selectedDate.slice(0,-5),
            code_task:567890,
            order_id:4567890,
            name: this.name,
            bloque:bloque
            
          }
          // eslint-disable-next-line no-console
          console.log(envio)
          
             axios
          .post(
            "https://us-central1-rayo-dev.cloudfunctions.net/whatsappEntel",
            envio
          )
          .then(() => {
              this.dialog =false
          
              this.snackText = "Agendamiento exitoso...Ya puedes cerrar esta ventana y recibirás un mensaje de Whatsapp";
              this.snackAlerta = true;
            
          })
          .catch((error) => {
              this.dialog =false
            // eslint-disable-next-line no-console
            console.log(error)
             this.snackText = "Algo inesperado ha ocurrido. Por favor contacta al equipo de soporte.";
              this.snackAlerta = true;

            this.showLoadingContainer = false;
          });


        }
      },
      reset () {
        this.$refs.form.reset()
        this.addressOkObject.valid = false
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    getAddressData(ev) {
      // eslint-disable-next-line no-console
      console.log(ev.name)
      // eslint-disable-next-line no-console
      console.log('validated address')
  // marca al addressObject
  this.addressOkObject.valid = true
      // eslint-disable-next-line no-console
      console.log(ev.name, ' ,', ev.locality,',',ev.administrative_area_level_1,',',ev.administrative_area_level_2)
      this.addressOkObject.name =ev.name, ' ,', ev.locality,',',ev.administrative_area_level_1,',',ev.administrative_area_level_2
      this.addressOkObject.coords = {latitude:ev.latitude, longitude:ev.longitude}
      // eslint-disable-next-line no-console
      // console.log(ev);

    },
    noResults(ev) {
      // eslint-disable-next-line no-console
      console.log(ev);
    },
    momentDate(inside) {
      return moment(inside).format("YYYY-MM-DD").toString();
    },
    evalOrderInURL() {
      let orderIdURL = this.$route.query.id;

      if (orderIdURL != undefined) {
        this.orderID = orderIdURL;
        this.getOrderDetails();
      }
    },
    getOrderType(jobTypeObj) {
      //Filter via Job Type - 0 for Pick Up, 1 for Delivery, 2 for Appointment and 3 for FOS
      let strOrderType;

      if (jobTypeObj.isPickup == 1 && jobTypeObj.isDelivery == 0) {
        strOrderType = "Recogida";
      }

      if (jobTypeObj.isPickup == 0 && jobTypeObj.isDelivery == 1) {
        strOrderType = "Entrega";
      }

      if (jobTypeObj.isPickup == 1 && jobTypeObj.isDelivery == 1) {
        strOrderType = "Recogida y Entrega";
      }

      return strOrderType;
    },
    getDC(task) {
      // eslint-disable-next-line no-console
      console.log(task);
      let DC = {};
      if (task.tags != undefined) {
        switch (task.tags) {
          case "PickupCD":
            DC.label = "Centro de distribución";
            DC.icon = "mdi-store";
            DC.color = "blue";
            break;

          case "DeliveryWarehouse":
            DC.label = "Bodega Rayo";
            DC.icon = "mdi-warehouse";
            DC.color = "cyan";
            break;
          case "PickupWarehouse":
            DC.label = "Bodega Rayo";
            DC.icon = "mdi-warehouse";
            DC.color = "cyan";
            break;

          case "DeliveryCliente":
            DC.label = "Cliente final";
            DC.icon = "mdi-account";
            DC.color = "green";
            break;
          default:
            DC.label = "";
            DC.icon = "mdi-information";
            DC.color = "";
            break;
        }

        return DC;
      }
    },
    getTaskType(taskType) {
      let taskTypeInfo = {};

      switch (taskType) {
        case 1:
          taskTypeInfo.label = "PickUp";
          taskTypeInfo.icon = "mdi-alpha-p-circle-outline";
          taskTypeInfo.color = "green";
          break;

        case 2:
          taskTypeInfo.label = "Delivery";
          taskTypeInfo.icon = "mdi-alpha-d-circle-outline";
          taskTypeInfo.color = "blue";
          break;

        case 3:
          taskTypeInfo.label = "Cita";
          taskTypeInfo.icon = "mdi-alpha-c-circle-outline";
          taskTypeInfo.color = "yellow";
          break;
        case 4:
          taskTypeInfo.label = "Devolución Local";
          taskTypeInfo.icon = "mdi-rrow-left-top";
          taskTypeInfo.color = "grey";
          break;

        default:
          taskTypeInfo.label = "Otro";
          taskTypeInfo.icon = "mdi-alpha-o-circle-outline";
          taskTypeInfo.color = "orange";
          break;
      }

      return taskTypeInfo;
    },

    getHistoricItemLabel(historicDescription) {
      let historicStatus;

      historicDescription = historicDescription.toLowerCase();

      this.statusArray.map((item) => {
        let itemStatus = item.status.toLowerCase();
        let descriptionMatchesStatus = historicDescription.search(itemStatus);

        if (descriptionMatchesStatus !== -1) {
          historicStatus = item;
        }
      });

      if (historicStatus === undefined) {
        historicStatus = {
          status: "OTRO",
          class: "otro",
          color: "#bf0811",
        };
      }

      return historicStatus;
    },

    buildTimelineItems(data) {
      let timelineCalculatedArray = [];
      let taskItems = data.tasks;
      // eslint-disable-next-line no-console
      console.log(taskItems);
      taskItems.map((task) => {
        //Cada tarea trabaja su propio histórico
        let historicItems = task.history_task;
        let rawTaskType = task.type_task;
        let arrayTemplateData = task.metadata_task.template_data;
        // let templateData = task.metadata_task.template_data[0];

        //Construye los items del timeline basandose en los eventos históricos de la tarea
        historicItems.map((historic) => {
          let rawDate = historic.date;
          let rawDescription = historic.description;

          let itemLabel = this.getHistoricItemLabel(rawDescription);

          //Construye objeto para el timeline
          historic.code_task = task.code_task;
          historic.dateRaw = new Date(rawDate);
          historic.dateFormat = this.getFormattedDate(rawDate);
          historic.label = itemLabel;
          historic.taskTypeInfo = this.getTaskType(rawTaskType);
          historic.DC = this.getDC(task);

          let labelEventType = itemLabel.status;

          //Maneja los eventos de estado

          switch (labelEventType) {
            case "CREADO":
            case "ASIGNADO":
            case "ACEPTADO":
            case "INICIADO":
            case "PROCESANDO":
            case "RECHAZADO":
            case "CANCELADO":
              historic.typeEvent = "historico";

              timelineCalculatedArray.push(historic);

              break;
            case "CANCELADA":
              historic.typeEvent = "historico";

              timelineCalculatedArray.push(historic);

              break;

            case "FINALIZADO CON ÉXITO":
            case "ha finalizado la tarea al realizar la autoasignación por multiscan.".toUpperCase():
            case "FINALIZADO FALLIDA":
            case "FALLIDO":
              //Evidencias solo se agregan en este tipo de evento
              historic.typeEvent = "adjunto";

              // if (templateData !== undefined) {
              // 	if (templateData.foto !== null) {
              // 		historic.attachment = templateData.foto;
              // 	} else {
              // 		historic.attachment = templateData.firma;
              // 	}

              // 	if(templateData.nota !== null){

              // 		timelineCalculatedArray.push({...historic, typeEvent: "nota", nota:templateData.nota});
              // 	}
              // }

              historic.attachment = [];
              for (let nn in arrayTemplateData) {
                // if (templateData !== undefined) {
                if (arrayTemplateData[nn].foto !== undefined) {
                  historic.attachment.push(arrayTemplateData[nn].foto);
                }

                if (arrayTemplateData[nn].firma != undefined) {
                  // eslint-disable-next-line no-console
                  console.log("here");
                  historic.attachment.push(arrayTemplateData.firma);
                }

                if (arrayTemplateData[nn].nota !== undefined) {
                  timelineCalculatedArray.push({
                    ...historic,
                    typeEvent: "nota",
                    nota: arrayTemplateData[nn].nota,
                  });
                }
                if (arrayTemplateData[nn].dropdown !== undefined) {
                  timelineCalculatedArray.push({
                    ...historic,
                    typeEvent: "dropdown",
                    dropdown: arrayTemplateData[nn].dropdown,
                  });
                }
              }

              timelineCalculatedArray.push(historic);

              // eslint-disable-next-line no-console
              console.log(timelineCalculatedArray);

              break;

            default:
              //No inserta el elemento al arreglo de items timeline
              break;
          }
        });
      });

      //Ordena array de elemtnos por fecha descendiente - eventos recientes se posicionan primero
      timelineCalculatedArray = timelineCalculatedArray.sort(
        (itemA, itemB) => itemB.dateRaw.getTime() - itemA.dateRaw.getTime()
      );

      this.dataOrderItems = timelineCalculatedArray;
      this.showTimeLineContainer = true;
      this.showLoadingContainer = false;
    },
    fixNamesJuan(nameIn) {
      switch (nameIn) {
        case "FINALIZADO CON ÉXITO":
          return "ÉXITO";
        case "HA FINALIZADO LA TAREA AL REALIZAR LA AUTOASIGNACIÓN POR MULTISCAN.":
          return "FIN DE AUTOASIGNACIÓN POR MULTISCAN";
        default:
          return nameIn;
      }
    },
    getOrderDetails() {
      try {
        //F98TEST01
        this.showLoadingContainer = true;

        let callParams = {
          order_id: this.orderID,
          cliente: this.customerName,
        };

        axios
          .post(
            "https://us-central1-rayo-api.cloudfunctions.net/getTareas/seguimiento",
            callParams
          )
          .then((response) => {
            let arrayData = response.data.data[0];

            // eslint-disable-next-line no-console
            console.log(arrayData);
            if (arrayData !== undefined) {
              this.buildTimelineItems(arrayData);
              // // eslint-disable-next-line no-console
              // console.log(JSON.stringify(arrayData))
            } else {
              this.snackText = "No se ha encontrado la orden en el sistema.";
              this.snackAlerta = true;
              this.displayError({
                error: "No se ha encontrado la orden en el sistema.",
                typeAlert: "error",
                displayTime: 3333,
              });

              this.showLoadingContainer = false;
            }
          })
          .catch((error) => {
            window.console.log("getOrderDetails -> error", error);

            this.displayError({
              error:
                "Ha ocurrido un problema al obtener la información de seguimiento.",
              typeAlert: "error",
              displayTime: 3333,
            });

            this.showLoadingContainer = false;
          });
      } catch (error) {
        window.console.log("getOrderDetails -> error", error);

        this.displayError({
          error:
            "Ha ocurrido un problema al obtener la información de seguimiento.",
          typeAlert: "error",
          displayTime: 3333,
        });

        this.showLoadingContainer = false;
      }
    },

    isMobile() {
      return isMobile;
    },

    getFormattedDate(stringDate) {
      return moment.utc(stringDate).format("DD/MM/YYYY LT");
    },
    onlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;

      if (keyCode < 48 || keyCode > 57) {
        if (keyCode !== 45) {
          event.preventDefault();
        }
      }
    },
    cleanError() {
      this.errorOnCall = false;
      this.errorMessage = "";
      this.classAlert = "fade-leave-to";
    },
    enableButton() {
      this.disabledButton = false;
    },
    turnLoader(showBoolean) {
      this.showLoadingContainer = showBoolean;
    },
    clearOrder() {
      this.orderID = "";
      this.errorMessage = "";
      this.classAlert = "fade-leave-to";
      this.errorOnCall = false;
      this.showTimeLineContainer = false;
    },
    displayError(errorOptions) {
      let hideError = this.cleanError.bind(this);
      let activateButton = this.enableButton.bind(this);
      let defaultErrorMessage = "No se pudo realizar la consulta.";
      let defaultErrorDivType = "warning";
      let defaultClassAlert = "fade-enter-active";
      let defaultDisplayTime = 2555;

      let error = errorOptions.error;
      let divErrorType = errorOptions.typeAlert;
      let alertClass = errorOptions.classAlert;
      let displayTime;

      if (error !== null || error !== undefined || error !== "") {
        this.errorMessage = error;
      } else {
        this.errorMessage = defaultErrorMessage;
      }

      if (
        divErrorType !== null ||
        divErrorType !== undefined ||
        divErrorType !== ""
      ) {
        this.typeAlert = divErrorType;
      } else {
        this.typeAlert = defaultErrorDivType;
      }

      if (
        alertClass !== null ||
        alertClass !== undefined ||
        alertClass !== ""
      ) {
        this.classAlert = alertClass;
      } else {
        this.typeAlert = defaultClassAlert;
      }

      if (
        isNaN(errorOptions.displayTime) !== true ||
        errorOptions.displayTime !== undefined ||
        errorOptions.displayTime !== null
      ) {
        displayTime = errorOptions.displayTime;
      } else {
        displayTime = defaultDisplayTime;
      }

      this.errorOnCall = true;
      this.showTimeLineContainer = false;
      this.showLoadingContainer = false;
      this.disabledButton = false;

      setTimeout(function () {
        hideError();
      }, displayTime);

      setTimeout(function () {
        activateButton();
      }, 1555);
    },

    //Form functions
    validateInput() {
      let orderID = this.orderID;
      let idMaxLength = this.maxInputLength;

      try {
        if (orderID == null || orderID.length == 0) {
          let errorObj = {
            error: "Número de orden no válido.",
            typeAlert: "warning",
            displayTime: 3000,
          };

          this.displayError(errorObj);

          return false;
        }

        if (orderID.length > idMaxLength) {
          let errorObj = {
            error:
              "El número de orden no debe contener más de " +
              idMaxLength +
              " carácteres",
            typeAlert: "warning",
            displayTime: 3000,
          };

          this.displayError(errorObj);
          return false;
        }

        return true;
      } catch (error) {
        let errorObj = {
          error: "Error al validar la orden.",
          typeAlert: "error",
          displayTime: 3500,
        };

        this.displayError(errorObj);

        return false;
      }
    },
    setStatusArray() {
      this.statusArray = [
        {
          status: "CREADO",
          class: "created",
          color: "#9f9f9f",
        },
        {
          status: "ASIGNADO",
          class: "assigned",
          color: "#f25c22",
        },
        {
          status: "DESASIGNADO",
          class: "assigned",
          color: "#f25c22",
        },

        {
          status: "ACEPTADO",
          class: "accepted",
          color: "#d60277",
        },
        {
          status: "INICIADO",
          class: "started",
          color: "#1b84bf",
        },
        {
          status: "PROCESANDO",
          class: "created",
          color: "#a2c6db",
        },
        {
          status: "FINALIZADO CON ÉXITO",
          class: "successful",
          color: "#1fc600",
        },
        {
          status: "FINALIZADO FALLIDA",
          class: "failed",
          color: "#bf0811",
        },
        {
          status: "FALLIDO",
          class: "failed",
          color: "#bf0811",
        },
        {
          status: "RECHAZADO",
          class: "failed",
          color: "#bf0811",
        },
        {
          status: "CANCELADO",
          class: "failed",
          color: "#990000",
        },
        {
          status: "CANCELADA",
          class: "failed",
          color: "#990000",
        },
        {
          status:
            "ha finalizado la tarea al realizar la autoasignación por multiscan.".toUpperCase(),
          class: "successful",
          color: "#1fc600",
        },
      ];
    },
  },
};
</script>
